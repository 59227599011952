import * as React from 'react'
import { Seo } from '../components/Seo'
import { graphql, navigate } from 'gatsby'
import { Layout } from '../components/Layout';
import Homepage from '../components/Homepage/Homepage';

const HomeTemplate = ({ data }) => {
  if (!data) return null;
  const heroDoc = data.prismicHomepage.data.hero.document.data;
  const homeServices = data.prismicHomepage.data.homepage_services;
  let maintenanceActive = data.prismicMaintenance.data.active;

  maintenanceActive = false;
  if (maintenanceActive && typeof window !== 'undefined') {
    navigate("/maintenance/");
    return null;
  } else {
    return (
      <Layout>
        <Seo title="Home" description="Tekode. We build experiences." />
        <div>
          Tekode Tours
        </div>
        <Homepage heroData={heroDoc} homepageServices={homeServices} />
      </Layout>
    )
  }
}

export const query = graphql`
query Hero {
    prismicHomepage {
      ...HomeFragment
    },
    prismicMaintenance {
      ...MaintenanceActiveFragment
    }
}`;

export const homeFragment = graphql`
fragment HomeFragment on PrismicHomepage {
  data {
    hero {
      document {
        ... on PrismicHero {
          id
          data {
            heading {
              text
            }
            sub_heading {
              text
            }
            cta_copy {
              text
            }
            cta_url {
              text
            }
          }
        }
      }
    }
    homepage_services {
      homepage_service {
        document {
          ... on PrismicService {
            id
            data {
              
      service_copy {
        richText
      }
        cta_copy {
        text
      }
      service_cta {
        text
      }
      service_image {
        alt
        copyright
        url
        gatsbyImageData
      }
      service_title {
        text
      }
      service_url {
        text
      }
      body {
        ... on PrismicSliceType {
          slice_type
        }
        ...ServiceDataBodyCopyBlock
        ...ServiceDataBodyCallToAction
        ...ServiceDataBodyCaseStudyCard
        ...ServiceDataBodyBasicStatistic
        ...ServiceDataBodyDecorativeStatistic
      }
            }
          }
        }
      }
    }
  }
}
`;

export const maintenanceActive = graphql`
fragment MaintenanceActiveFragment on PrismicMaintenance {
  data {
    active
  }
}
`

export default HomeTemplate;